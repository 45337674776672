import React, { useEffect, useState } from "react";
import AdminLayout from "../../../layouts/AdminLayout";
import Subheader from "../../../components/ui/Subheader";
import { Form, FormProps, Input, Select, Space } from "antd";
import IconButton from "../../../components/common/IconButton";
import { useAppDispatch, useAppSelector } from "../../../store/store";
import { ArrayOption, selectSearchOption } from "../../../helpers/siteConfig";
import {
  createPowerAuthorityAsync,
  createSubAuthorityAsync,
  getPowerAuthorityAsync,
} from "../../../store/features/admin/billGenerate/billGenerateAPI";
import { useParams } from "react-router-dom";

export default function Authority() {
  const { viewId } = useParams();
  const [form] = Form.useForm();
  const [formSub] = Form.useForm();
  const dispatch = useAppDispatch();
  const [loadingPower, setLoadingPower] = useState(false);
  const [loadingSub, setLoadingSub] = useState(false);

  const { powerAuthority } = useAppSelector((state) => state.billGenerate);

  const onFinish: FormProps<any>["onFinish"] = async (values) => {
    setLoadingPower(true);
    try {
      await dispatch(
        createPowerAuthorityAsync({
          data: { powerAuthorityName: values?.powerAuthorityName },
          others: {
            reRender,
          },
        })
      );
      // form.setFieldsValue({ powerAuthorityName: "" });
    } finally {
      setLoadingPower(false);
    }
  };

  const onFinishSub: FormProps<any>["onFinish"] = async (values) => {
    setLoadingSub(true);
    try {
      await dispatch(
        createSubAuthorityAsync({
          data: {
            powerAuthorityId: values?.powerAuthorityId,
            subAuthorityName: values?.subAuthorityName,
          },
          others: {
            reRender,
          },
        })
      );
      // form.resetFields();
      // form.setFieldsValue({
      //   powerAuthorityId: "",
      //   subAuthorityName: "",
      // });
    } finally {
      setLoadingSub(false);
    }
  };

  useEffect(() => {
    dispatch(getPowerAuthorityAsync());
  }, [dispatch]);

  // redirect and re-render function
  const reRender = (val: boolean) => {
    if (val) {
      form.resetFields();
      formSub.resetFields();
    }
  };

  return (
    <>
      <AdminLayout title="Authority Create">
        <Subheader title="Authority Create"></Subheader>

        <div className="mb-4">
          <h4 className="text-base font-semibold mb-3 mt-3">
            Create Power Authority
          </h4>

          <Form form={form} onFinish={onFinish}>
            <div className="flex justify-start items-center gap-4">
              <Form.Item
                name="powerAuthorityName"
                label="Power Authority"
                rules={[
                  {
                    required: true,
                    message: "Please input power authority",
                  },
                ]}
                className="mb-0 pb-0 w-[50%]"
              >
                <Input
                  size="large"
                  disabled={viewId ? true : false}
                  placeholder="Power Authority"
                />
              </Form.Item>

              <Space size="middle">
                <IconButton
                  type="text"
                  className="secondary-btn h-[38px] w-[100px]"
                  htmlType="submit"
                  disabled={loadingPower}
                >
                  {loadingPower ? "Submitting..." : "Submit"}
                </IconButton>
              </Space>
            </div>
          </Form>
        </div>

        <div className="mt-8">
          <h4 className="text-base font-semibold mb-3 mt-3">
            Create Sub Authority
          </h4>

          <Form form={formSub} onFinish={onFinishSub}>
            <div className="mb-4">
              <Form.Item
                name="powerAuthorityId"
                label="Power Authority"
                rules={[
                  {
                    required: true,
                    message: "Please select power authority",
                  },
                ]}
                className="mb-0 pb-0 w-[50%]"
              >
                <Select
                  placeholder="Select"
                  size="large"
                  allowClear
                  showSearch
                  // loading={loading}
                  filterOption={selectSearchOption}
                  options={ArrayOption(
                    powerAuthority,
                    "powerAuthorityName",
                    "_id",
                    "up"
                  )}
                  disabled={viewId ? true : false}
                  //   onChange={handleSelectChange}
                  className="mb-0 pb-0"
                />
              </Form.Item>
            </div>
            <div className="flex justify-start items-center gap-4">
              <Form.Item
                name="subAuthorityName"
                label="Sub Authority"
                rules={[
                  {
                    required: true,
                    message: "Please input sub authority",
                  },
                ]}
                className="mb-0 pb-0 w-[50%]"
              >
                <Input
                  size="large"
                  disabled={viewId ? true : false}
                  placeholder="Sub Authority"
                />
              </Form.Item>

              <Space size="middle">
                <IconButton
                  type="text"
                  className="secondary-btn h-[38px] w-[100px]"
                  htmlType="submit"
                  disabled={loadingSub}
                >
                  {loadingSub ? "Submitting..." : "Submit"}
                </IconButton>
              </Space>
            </div>
          </Form>
        </div>
      </AdminLayout>
    </>
  );
}
