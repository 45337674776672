import { createElectricitySupplierAsync } from "../store/features/admin/electricitySupplier/electricitySupplierAPI";

export const BASE_API = {
  // Auth -(Account Management)
  login: "/user/login",
  loginFinance: "/proprietor/ebill/login",

  // Geo Info -(GEO Setup)
  createRegion: "/geo/create-region",
  updateRegion: "/geo/update-region-by",
  getAllRegion: "/geo/get-regions",
  getSingleRegion: "/geo/get-region-by",
  getCountRegion: "/geo/count-regions",

  createArea: "/geo/create-area",
  updateArea: "/geo/update-area-by",
  getAllArea: "/geo/get-areas",
  getSingleArea: "/geo/get-area-by",
  getCountArea: "/geo/counts-area",

  createTerritory: "/geo/create-teritory",
  updateTerritory: "/geo/update-teritory-by",
  getAllTerritory: "/geo/get-teritorys",
  getSingleTerritory: "/geo/get-teritory-by",
  getCountTerritory: "/geo/counts-teritory",

  // Resource -(User)
  checkEmail: "/user/check-email",
  checkPhone: "/user/check-phone",
  checkNID: "/user/check-nid",

  createUser: "/user/create-user",
  getAllUsers: "/user/get-users",
  getCountUsers: "/user/counts-user",

  // Network -(Tower Management)
  checkTowerName: "/tower/check-tower-name",
  checkSiteCode: "/tower/check-site-code",

  createTower: "/tower/create-tower",
  getAllTower: "/tower/get-towers",
  getCountTower: "/tower/counts-tower",

  createTowerTicket: "/tower/create-ticket",
  towerTicketNotification: "/tower/send-notification-to-related-pg",
  sendFeedback: "/tower/admin-give-feedback",
  networkLogView: "/tower/get-single-tower-log",

  // Supplier
  createSupplier: "/user/create-supplier",
  getAllSupplier: "/user/get-suppliers",
  getCountSupplier: "/user/counts-suppliers",
  getSupplierUser: "/user/get-user-by-supplier",
  getCountUserSupplier: "/user/get-number-of-user-by-supplier",

  // Dashboard
  getActiveAndInactiveTower: "/tower/get-active-inactive-numer-of-tower",
  getActiveAndInactiveMap: "/tower/get-active-inactvie-route-for-map",

  // e-bill
  // Electricity Supplier
  createElectricitySupplier: "/proprietor/ebill/supplier-create",
  getAllElectricitySupplier: "/proprietor/ebill/get-supplier",

  // miter
  createMiter: "/proprietor/miter/create",
  getAllMiter: "/proprietor/miter/get-miter-data",

  // bill generate
  createBillGenerate: "/proprietor/bill/generate",
  getAllBillGenerate: "/proprietor/bill/get-generate",
  requestToBill: "/proprietor/bill/send-to-finance-team",
  billSendToAdmin: "/proprietor/bill/send-back-to-admin",
  getLastFiveDayRemaining: "/proprietor/ebill/get-last-five-day-remaining",
  getLastFiveDayRemainingData:
    "proprietor/ebill/get-last-five-day-remaining-download",
  uploadBillImage: "/proprietor/bill/set-photo-by-region",
  uploadFinanceBillImage: "/proprietor/bill/set-photo-by-finance",
  getPowerAuthority: "/proprietor/authority/power-authority/data",
  getSubAuthority: "/proprietor/authority/sub-authority/data",
  createPowerAuthority: "/proprietor/authority/power-authority/create",
  createSubAuthority: "/proprietor/authority/sub-authority/create",
  createElectricityBillInitiate: "/proprietor/electricity-bill/pre/execute",
  createElectricityBillExecute: "/proprietor/electricity-bill/execute",
  createElectricityBillBulkProcess:
    "/proprietor/electricity-bill/pre/bluk/execute",

  // dashboard
  getMonthYearBill: "/proprietor/bill/dashboard-month-year",
  getSiteBill: "/proprietor/bill/dashboard-site-wise-bill",

  // Notifications
  countNotification: "/proprietor/bill/count-notification",
  getNotification: "/proprietor/bill/get-notification",
  checkNotification: "/proprietor/billl/check-notification",

  // Image -(File Upload)
  fileUpload: `/tower/upload-image`,
};
