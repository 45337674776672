import { createSlice } from "@reduxjs/toolkit";
import { BillGenerateInitialStatus } from "../../../../types/redux";
import {
  createElectricityBillBulkProcessAsync,
  createElectricityBillExecuteAsync,
  createElectricityBillInitiateAsync,
  createPowerAuthorityAsync,
  createSubAuthorityAsync,
  getAllBillGenerateAsync,
  getLastFiveDayRemainingAsync,
  getLastFiveDayRemainingDataAsync,
  getPowerAuthorityAsync,
  getSubAuthorityAsync,
  requestToBillAsync,
  uploadBillAsync,
} from "./billGenerateAPI";

const initialState: BillGenerateInitialStatus<any> = {
  loading: false,
  postLoading: false,
  updateLoading: false,
  deleteLoading: false,
  viewLoading: false,
  allBillGenerate: [],
  remainingDay: 0,
  remainingData: [],
  // singleBillGenerate: null,
  billGenerateCount: 0,
  powerAuthority: [],
  subAuthority: [],
  error: null,
  electricityBillInitiate: [],
  electricityBillBulkProccess: [],
  electricityBillExecute: [],
};

const billGenerateSlice = createSlice({
  name: "admin-billGenerate",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    // create billGenerate
    builder
      .addCase(uploadBillAsync.pending, (state) => {
        state.postLoading = true;
      })
      .addCase(uploadBillAsync.fulfilled, (state) => {
        state.postLoading = false;
      })
      .addCase(uploadBillAsync.rejected, (state) => {
        state.postLoading = false;
      });
    // create request to pay bill
    builder
      .addCase(requestToBillAsync.pending, (state) => {
        state.updateLoading = true;
      })
      .addCase(requestToBillAsync.fulfilled, (state) => {
        state.updateLoading = false;
      })
      .addCase(requestToBillAsync.rejected, (state) => {
        state.updateLoading = false;
      });

    // get all billGenerate
    builder
      .addCase(getAllBillGenerateAsync.pending, (state) => {
        state.loading = true;
      })
      .addCase(getAllBillGenerateAsync.fulfilled, (state, action) => {
        state.loading = false;
        state.allBillGenerate = action.payload;
      })
      .addCase(getAllBillGenerateAsync.rejected, (state) => {
        state.loading = false;
        state.allBillGenerate = [];
      });
    builder
      .addCase(getLastFiveDayRemainingAsync.pending, (state) => {
        // state.loading = true;
        state.remainingDay = 0;
      })
      .addCase(getLastFiveDayRemainingAsync.fulfilled, (state, action) => {
        // state.loading = false;
        state.remainingDay = action.payload;
      })
      .addCase(getLastFiveDayRemainingAsync.rejected, (state) => {
        // state.loading = false;
        state.remainingDay = 0;
      });
    builder
      .addCase(getLastFiveDayRemainingDataAsync.pending, (state) => {
        // state.loading = true;
        state.remainingData = [];
      })
      .addCase(getLastFiveDayRemainingDataAsync.fulfilled, (state, action) => {
        // state.loading = false;
        state.remainingData = action.payload;
      })
      .addCase(getLastFiveDayRemainingDataAsync.rejected, (state) => {
        // state.loading = false;
        state.remainingData = [];
      });

    // Get power authority
    builder
      .addCase(getPowerAuthorityAsync.pending, (state) => {
        state.loading = true;
      })
      .addCase(getPowerAuthorityAsync.fulfilled, (state, action) => {
        state.loading = false;
        state.powerAuthority = action.payload;
      })
      .addCase(getPowerAuthorityAsync.rejected, (state) => {
        state.loading = false;
        state.powerAuthority = [];
      });

    // Get Sub Authority
    builder
      .addCase(getSubAuthorityAsync.pending, (state) => {
        state.loading = true;
      })
      .addCase(getSubAuthorityAsync.fulfilled, (state, action) => {
        state.loading = false;
        state.subAuthority = action.payload;
      })
      .addCase(getSubAuthorityAsync.rejected, (state) => {
        state.loading = false;
        state.subAuthority = [];
      });

    // Create Power Authority
    builder
      .addCase(createPowerAuthorityAsync.pending, (state) => {
        state.postLoading = true;
      })
      .addCase(createPowerAuthorityAsync.fulfilled, (state) => {
        state.postLoading = false;
      })
      .addCase(createPowerAuthorityAsync.rejected, (state) => {
        state.postLoading = false;
      });

    // Create Sub Authority
    builder
      .addCase(createSubAuthorityAsync.pending, (state) => {
        state.postLoading = true;
      })
      .addCase(createSubAuthorityAsync.fulfilled, (state) => {
        state.postLoading = false;
      })
      .addCase(createSubAuthorityAsync.rejected, (state) => {
        state.postLoading = false;
      });

    // Create Electricity Bill Initiate
    builder
      .addCase(createElectricityBillInitiateAsync.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(
        createElectricityBillInitiateAsync.fulfilled,
        (state, action) => {
          state.loading = false;
          state.electricityBillInitiate = action.payload;
        }
      )
      .addCase(createElectricityBillInitiateAsync.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload ?? "Something went wrong!";
      });

    // Create Electricity Bill Bulk Process
    builder
      .addCase(createElectricityBillBulkProcessAsync.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(
        createElectricityBillBulkProcessAsync.fulfilled,
        (state, action) => {
          state.loading = false;
          state.electricityBillBulkProccess = action.payload;
        }
      )
      .addCase(
        createElectricityBillBulkProcessAsync.rejected,
        (state, action) => {
          state.loading = false;
          state.error = action.payload ?? "Something went wrong!";
        }
      );

    // Create Electricity Bill Execute
    builder
      .addCase(createElectricityBillExecuteAsync.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(createElectricityBillExecuteAsync.fulfilled, (state, action) => {
        state.loading = false;
        state.electricityBillExecute = action.payload;
        console.log("Slice", action.payload);
      })
      .addCase(createElectricityBillExecuteAsync.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload ?? "Something went wrong!";
      });

    // builder
    //   .addCase(billSendToAdminAsync.pending, (state) => {
    //     state.updateLoading = true;
    //   })
    //   .addCase(billSendToAdminAsync.fulfilled, (state) => {
    //     state.updateLoading = false;
    //   })
    //   .addCase(billSendToAdminAsync.rejected, (state) => {
    //     state.updateLoading = false;
    //   });
    // get single billGenerate
    // builder
    //   .addCase(getSingleBillGenerateAsync.pending, (state) => {
    //     state.viewLoading = true;
    //   })
    //   .addCase(getSingleBillGenerateAsync.fulfilled, (state, action) => {
    //     state.viewLoading = false;
    //     state.singleBillGenerate = action.payload;
    //   })
    //   .addCase(getSingleBillGenerateAsync.rejected, (state) => {
    //     state.viewLoading = false;
    //     state.singleBillGenerate = null;
    //   });
  },
});

export default billGenerateSlice;
