import { DatePicker, Form, FormProps, Select, Space } from "antd";
import React, { useEffect, useState } from "react";
import { useLocation, useParams } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../../store/store";
import { stringToArray } from "../../../utils/array";
import { getAllRegionAsync } from "../../../store/features/admin/geoInfo/geoAPI";
import {
  getAllBillGenerateAsync,
  getPowerAuthorityAsync,
  getSubAuthorityAsync,
} from "../../../store/features/admin/billGenerate/billGenerateAPI";
import { ArrayOption, selectSearchOption } from "../../../helpers/siteConfig";
import IconButton from "../../../components/common/IconButton";
import SuccessIcon from "../../../assets/icons/SuccessIcon";
import moment from "moment";

export default function CreateBillForm() {
  const { viewId } = useParams();

  const [form] = Form.useForm();
  const dispatch = useAppDispatch();
  const [selectedPowerAuthority, setSelectedPowerAuthority] = useState<
    string | null
  >(null);
  const [filteredSubAuthority, setFilteredSubAuthority] = useState<any[]>([]);

  const { regions } = useAppSelector((state) => state.npGeo);
  const { powerAuthority, subAuthority } = useAppSelector(
    (state) => state.billGenerate
  );

  const onFinish: FormProps<any>["onFinish"] = (values) => {
    const params: any = {
      region: values?.regionId,
      miterType: values?.miterType,
      status: values?.status,
      month: moment(new Date(values?.month)).format("MMMM"),
      year: moment(new Date(values?.year)).format("YYYY"),
      fromDate: moment(new Date(values?.fromDate)).format("YYYY-MM-DD"),
      toDate: moment(new Date(values?.toDate)).format("YYYY-MM-DD"),
      powerAuthority: values?.powerAuthorityId,
      subAuthority: values?.subAuthorityId,
    };

    dispatch(
      getAllBillGenerateAsync({
        data: params,
        others: {},
      })
    );
  };

  const handleSelectChange = (value: string) => {
    setSelectedPowerAuthority(value);
    form.setFieldsValue({ subAuthority: undefined });
  };

  useEffect(() => {
    dispatch(getAllRegionAsync({}));
    dispatch(getPowerAuthorityAsync());
  }, [dispatch]);

  useEffect(() => {
    if (selectedPowerAuthority) {
      dispatch(
        getSubAuthorityAsync({
          params: { powerAuthorityId: selectedPowerAuthority },
        })
      );
    } else {
      setFilteredSubAuthority([]);
    }
  }, [dispatch, selectedPowerAuthority]);

  return (
    <>
      <div className="mb-4">
        <h4 className="text-base font-semibold mb-3 mt-3">
          Electricty Payment Request
        </h4>
        <Form onFinish={onFinish}>
          <div className="w-full overflow-x-auto">
            <table className="w-full bg-white border border-gray-200 shadow-md rounded-lg">
              <thead>
                <tr className="bg-[#28b06c]">
                  <th
                    colSpan={3}
                    className="px-6 py-1 text-white text-lg text-start"
                  >
                    Parameters
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr className="bg-gray-100 border-b border-gray-200">
                  <td className="px-6 py-3 w-[30%] border-r border-gray-200 text-base font-semibold">
                    Power Authority
                  </td>
                  <td className="px-6 py-3 w-[70%] border-r border-gray-200">
                    {" "}
                    <Form.Item
                      name="powerAuthorityId"
                      // label="Power Authority"
                      rules={[
                        {
                          required: true,
                          message: "Please select power authority",
                        },
                      ]}
                      className="mb-0 pb-0 w-[50%]"
                    >
                      <Select
                        placeholder="Select"
                        size="large"
                        allowClear
                        showSearch
                        // loading={loading}
                        filterOption={selectSearchOption}
                        options={ArrayOption(
                          powerAuthority,
                          "powerAuthorityName",
                          "_id",
                          "up"
                        )}
                        disabled={viewId ? true : false}
                        onChange={handleSelectChange}
                        className="mb-0 pb-0"
                      />
                    </Form.Item>
                  </td>
                </tr>

                <tr className="bg-white border-b border-gray-200">
                  <td className="px-6 py-3 w-[30%] border-r border-gray-200 text-base font-semibold">
                    Sub Authority
                  </td>
                  <td className="px-6 py-3 w-[70%] border-r border-gray-200">
                    <Form.Item
                      name="subAuthorityId"
                      //  label="Sub Authority"
                      rules={[
                        {
                          required: true,
                          message: "Please select sub authority",
                        },
                      ]}
                      className="mb-0 pb-0 w-[50%]"
                    >
                      <Select
                        placeholder="Select"
                        size="large"
                        allowClear
                        showSearch
                        // loading={loading}
                        filterOption={selectSearchOption}
                        options={ArrayOption(
                          subAuthority,
                          "subAuthorityName",
                          "_id",
                          "up"
                        )}
                        disabled={viewId ? true : false}
                        className="mb-0 pb-0"
                      />
                    </Form.Item>
                  </td>
                </tr>

                <tr className="bg-gray-100 border-b border-gray-200">
                  <td className="px-6 py-3 w-[30%] border-r border-gray-200 text-base font-semibold">
                    Regions{" "}
                    <span className="text-sm font-normal">For filter only</span>
                  </td>
                  <td className="px-6 py-3 w-[70%] border-r border-gray-200">
                    {" "}
                    <Form.Item
                      name="regionId"
                      //  label="Regions For Filter Only"
                      rules={[
                        {
                          required: true,
                          message: "Please select regions",
                        },
                      ]}
                      className="mb-0 pb-0 w-[50%]"
                    >
                      <Select
                        placeholder="Select region"
                        size="large"
                        allowClear
                        showSearch
                        // loading={loading}
                        filterOption={selectSearchOption}
                        options={ArrayOption(regions, "regionName", "_id")}
                        disabled={viewId ? true : false}
                        className="mb-0 pb-0"
                      />
                    </Form.Item>
                  </td>
                </tr>

                <tr className="bg-white border-b border-gray-200">
                  <td className="px-6 py-3 w-[30%] border-r border-gray-200 text-base font-semibold">
                    Electricity Billing Type
                  </td>
                  <td className="px-6 py-3 w-[70%] border-r border-gray-200">
                    <Form.Item
                      name="miterType"
                      //  label="Electricity Billing Type"
                      rules={[
                        {
                          required: true,
                          message: "Please select electricity billing type",
                        },
                      ]}
                      className="mb-0 pb-0 w-[50%]"
                    >
                      <Select
                        placeholder="Select"
                        size="large"
                        allowClear
                        showSearch
                        // loading={loading}
                        filterOption={selectSearchOption}
                        options={[
                          {
                            label: "Prepaid",
                            value: "pre",
                          },
                          {
                            label: "Postpaid",
                            value: "post",
                          },
                        ]}
                        disabled={viewId ? true : false}
                        className="mb-0 pb-0"
                      />
                    </Form.Item>
                  </td>
                </tr>

                <tr className="bg-gray-100 border-b border-gray-200">
                  <td className="px-6 py-3 w-[30%] border-r border-gray-200 text-base font-semibold">
                    Status
                  </td>
                  <td className="px-6 py-3 w-[70%] border-r border-gray-200">
                    <Form.Item
                      name="status"
                      //  label="Electricity Billing Type"
                      rules={[
                        {
                          required: true,
                          message: "Please select status",
                        },
                      ]}
                      className="mb-0 pb-0 w-[50%]"
                    >
                      <Select
                        placeholder="Select"
                        size="large"
                        allowClear
                        showSearch
                        // loading={loading}
                        filterOption={selectSearchOption}
                        options={[
                          {
                            label: "Paid",
                            value: "4",
                          },
                          {
                            label: "Unpaid",
                            value: "1",
                          },
                          {
                            label: "Verify",
                            value: "2",
                          },
                          {
                            label: "Pending",
                            value: "3",
                          },
                          {
                            label: "Settlement",
                            value: "5",
                          },
                        ]}
                        disabled={viewId ? true : false}
                        className="mb-0 pb-0"
                      />
                    </Form.Item>
                  </td>
                </tr>

                <tr className="bg-white border-b border-gray-200">
                  <td className="px-6 py-3 w-[30%] border-r border-gray-200 text-base font-semibold">
                    Billing Month/Year
                  </td>
                  <td className="px-6 py-3 w-[70%] border-r border-gray-200">
                    <div className="grid grid-cols-2 gap-x-4">
                      <div>
                        <h5 className="text-base font-medium mb-1">Month</h5>
                        <Form.Item
                          name="month"
                          // label="Month"
                          rules={[
                            {
                              required: true,
                              message:
                                "Please select electricity billing month",
                            },
                          ]}
                          className="mb-0 pb-0"
                        >
                          <DatePicker
                            picker="month"
                            className="!w-[100%] !h-[44px] mb-0 pb-0"
                          />
                        </Form.Item>
                      </div>

                      <div>
                        <h5 className="text-base font-medium mb-1">Year</h5>
                        <Form.Item
                          name="year"
                          // label="Year"
                          rules={[
                            {
                              required: true,
                              message: "Please select electricity billing year",
                            },
                          ]}
                          className="mb-0 pb-0"
                        >
                          <DatePicker
                            picker="year"
                            className="!w-[100%] !h-[44px] mb-0 pb-0"
                          />
                        </Form.Item>
                      </div>
                    </div>
                  </td>
                </tr>

                {/* <tr className="bg-white border-b border-gray-200">
                  <td className="px-6 py-3 w-[30%] border-r border-gray-200 text-base font-semibold">
                    Date
                  </td>
                  <td className="px-6 py-3 w-[70%] border-r border-gray-200">
                    <div className="grid grid-cols-2 gap-x-4">
                      <div>
                        <h5 className="text-base font-medium mb-1">Form</h5>
                        <Form.Item
                          name="fromDate"
                          // label="From"
                          rules={[
                            {
                              required: true,
                              message: "Please select electricity date from",
                            },
                          ]}
                          className="mb-0 pb-0"
                        >
                          <DatePicker className="!w-[100%] !h-[44px] mb-0 pb-0" />
                        </Form.Item>
                      </div>

                      <div>
                        <h5 className="text-base font-medium mb-1">To</h5>
                        <Form.Item
                          name="toDate"
                          // label="To"
                          rules={[
                            {
                              required: true,
                              message: "Please select electricity date to",
                            },
                          ]}
                          className="mb-0 pb-0"
                        >
                          <DatePicker className="!w-[100%] !h-[44px] mb-0 pb-0" />
                        </Form.Item>
                      </div>
                    </div>
                  </td>
                </tr> */}

                <tr className="bg-gray-100 border-b border-gray-200">
                  <td className="px-6 py-3 w-[30%] border-r border-gray-200 text-base font-semibold"></td>
                  <td className="px-6 py-3 w-[70%] border-r border-gray-200">
                    <Space size="middle">
                      <IconButton
                        type="text"
                        className="secondary-btn"
                        icon={<SuccessIcon />}
                        htmlType="submit"
                      >
                        Render Electricity Readings
                      </IconButton>
                    </Space>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </Form>
      </div>
    </>
  );
}
