import React, { useState } from "react";
import CustomModal from "../../common/CustomModal";
import IconButton from "../../common/IconButton";
import { Space, Spin } from "antd";
import { useAppDispatch } from "../../../store/store";
import {
  createElectricityBillBulkProcessAsync,
  createElectricityBillInitiateAsync,
} from "../../../store/features/admin/billGenerate/billGenerateAPI";

type Props = {
  open: boolean;
  handleCloseConfirm: () => void;
  handleCloseUnpaid: () => void;
  data?: any;
  reRender: (val: boolean) => void;
  selectedFullData?: any;
  unpaidFormData?: any;
};

export default function ConfirmModal({
  open,
  handleCloseConfirm,
  handleCloseUnpaid,
  data,
  selectedFullData,
  unpaidFormData,
  reRender,
}: Props) {
  const [isLoading, setIsLoading] = useState(false);
  const dispatch = useAppDispatch();

  const handleConfirm = async () => {
    setIsLoading(true);
    try {
      if (selectedFullData.length > 0) {
        await dispatch(
          createElectricityBillBulkProcessAsync({
            data: selectedFullData?.map((data: any) => ({
              billerType: "BPDB",
              billType:
                data?.miterType?.toLowerCase() === "post"
                  ? "POSTPAID"
                  : "PREPAID",
              amount: data?.totalAmount,
              // meterNo: data?.miterNumber,
              meterNo: data?.miterNumber,
              sourceWallet: "8801317479142",
              pin: "147258",
              reference: unpaidFormData.reference,
              appVersion: "4.2.0",
              phoneBrand: "AE9310",
              phoneOs: "13",
              osVersion: "13",
              billId: data?._id,
            })),
            others: {
              reRender,
            },
          })
        );
      } else {
        await dispatch(
          createElectricityBillInitiateAsync({
            data: {
              billerType: "BPDB",
              billType:
                data?.miterType?.toLowerCase() === "post"
                  ? "POSTPAID"
                  : "PREPAID",
              amount: data?.totalAmount,
              // meterNo: data?.miterNumber,
              meterNo: data?.miterNumber,
              sourceWallet: "8801317479142",
              pin: "147258",
              reference: unpaidFormData.reference,
              appVersion: "4.2.0",
              phoneBrand: "AE9310",
              phoneOs: "13",
              osVersion: "13",
              billId: data?._id,
            },
            others: {
              reRender,
            },
          })
        );
      }

      handleCloseConfirm();
      handleCloseUnpaid();
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <>
      <CustomModal
        width={600}
        title="Confirm"
        open={open}
        handleClose={handleCloseConfirm}
      >
        <div className="p-6">
          <h3 className="text-center mb-3">
            Are you sure you want to proceed with the payment?
          </h3>
          <div className="flex justify-center">
            <Space size="middle">
              <IconButton
                type="text"
                className="secondary-btn h-[38px] w-[100px]"
                disabled={isLoading}
                onClick={() => {
                  handleConfirm();
                }}
              >
                {isLoading ? (
                  <>
                    {" "}
                    <Spin size="small" className="mr-2" /> Confirm{" "}
                  </>
                ) : (
                  "Confirm"
                )}
              </IconButton>
            </Space>
          </div>
        </div>
      </CustomModal>
    </>
  );
}
