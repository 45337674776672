import React, { useState } from "react";
import CustomModal from "../../common/CustomModal";
import { Form, FormProps, Input, Space } from "antd";
import IconButton from "../../common/IconButton";
import useModal from "../../../hooks/useModal";
import { useAppDispatch, useAppSelector } from "../../../store/store";

import ConfirmModal from "./ConfirmModal";

type Props = {
  open: boolean;
  handleCloseUnpaid: () => void;
  data?: any;
  selectedFullData?: any;
};

export default function UnpaidModal({
  handleCloseUnpaid,
  data,
  selectedFullData,
}: Props) {
  const [form] = Form.useForm();
  const dispatch = useAppDispatch();
  const { open, toggle, handleOpen } = useModal();
  const [unpaidFormData, setUnpaidFormData] = useState<React.Key[]>([]);

  const onFinish: FormProps<any>["onFinish"] = async (values) => {
    try {
      setUnpaidFormData(values);
    } finally {
    }
  };

  // redirect and re-render function
  const reRender = (val: boolean) => {
    if (val) {
      form.resetFields();
    }
  };

  return (
    <>
      {open.type === "CONFIRM" && (
        <ConfirmModal
          open={open.open}
          handleCloseConfirm={toggle}
          reRender={reRender}
          handleCloseUnpaid={handleCloseUnpaid}
          data={data}
          selectedFullData={selectedFullData}
          unpaidFormData={unpaidFormData}
        />
      )}

      <CustomModal
        width={600}
        title="Unpaid Form"
        open
        handleClose={handleCloseUnpaid}
      >
        <div className="p-6">
          <Form form={form} onFinish={onFinish}>
            <Form.Item
              name="reference"
              label="Reference"
              rules={[
                {
                  required: true,
                  message: "Please input reference",
                },
              ]}
              className="mb-4 pb-0 w-[100%]"
            >
              <Input size="large" placeholder="Reference" />
            </Form.Item>

            {/* <Form.Item
              name="fullName"
              label="Full Name"
              rules={[
                {
                  required: true,
                  message: "Please input full name",
                },
              ]}
              className="mb-4 pb-0 w-[100%]"
            >
              <Input size="large" placeholder="Full Name" />
            </Form.Item> */}

            {/* <Form.Item
              name="appVersion"
              label="App Version"
              rules={[
                {
                  required: true,
                  message: "Please input app version",
                },
              ]}
              className="mb-4 pb-0 w-[100%]"
            >
              <Input size="large" placeholder="App Version" />
            </Form.Item> */}

            {/* <Form.Item
              name="phoneBrand"
              label="Phone Brand"
              rules={[
                {
                  required: true,
                  message: "Please input phone brand",
                },
              ]}
              className="mb-4 pb-0 w-[100%]"
            >
              <Input size="large" placeholder="Phone Brand" />
            </Form.Item> */}

            {/* <Form.Item
              name="phoneOs"
              label="Phone Os"
              rules={[
                {
                  required: true,
                  message: "Please input phoneOs",
                },
              ]}
              className="mb-4 pb-0 w-[100%]"
            >
              <Input size="large" placeholder="Phone Os" />
            </Form.Item> */}

            {/* <Form.Item
              name="osVersion"
              label="Os Version"
              rules={[
                {
                  required: true,
                  message: "Please input osVersion",
                },
              ]}
              className="mb-4 pb-0 w-[100%]"
            >
              <Input size="large" placeholder="Os Version" />
            </Form.Item> */}

            <div className="flex justify-end">
              <Space size="middle">
                <IconButton
                  type="text"
                  className="secondary-btn h-[38px] w-[100px]"
                  htmlType="submit"
                  onClick={() => {
                    handleOpen({
                      type: "CONFIRM",
                    });
                  }}
                >
                  Submit
                </IconButton>
              </Space>
            </div>
          </Form>
        </div>
      </CustomModal>
    </>
  );
}
